<template>
  <div class="syncData">
    <div class="date item">
      <van-cell title="选择日期区间：" :value="date" @click="show = true" />
      <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="new Date(2020,0,1)" :max-date="maxDate" :allow-same-day="true" />
    </div>
    <div class="date item platform">
      <van-cell title="选择平台："/>
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="currentPlatform" :options="platform" @change="selectPlat" />
      </van-dropdown-menu>
    </div>
    <div class="">
      <van-row class="item">
        <van-col :span="8">
          <van-cell title="选择凭证：" />
        </van-col>
        <van-col :span="8">
          <van-cell>
            <van-checkbox v-model="checked" @change="checkAll"
              >全选</van-checkbox
            >
          </van-cell>
        </van-col>
      </van-row>
      <div class="checkList">
        <van-checkbox-group v-model="result" ref="checkboxGroup">
          <van-checkbox :name="item.id" icon-size="16px" v-for="item in credentail" :key="item.id">{{item.name}}</van-checkbox>
        </van-checkbox-group>
      </div>
    </div>
    <div class="btnWrap" >
        <van-button size="small" @click="submitSyncJob">提交</van-button>
    </div>
  </div>
</template>
<script>
import {credentailList} from '@/api/user'
import {spendJob} from '@/api/campaign'
import globalData from '@/minixs/data.js'
import { Notify } from 'vant';
export default {
  props: {
    syncDataShow: {
      type: Boolean,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  mixins:[globalData],
  computed: {

  },
  data() {
    return {
      newValue: null,
      date: "",
      show: false,
      result: [],
      checked: true,
      cardList:[],
      maxDate: new Date(),
      dateRang:[],
      currentPlatform:JSON.parse(localStorage.getItem('platform'))||null,
    };
  },
  computed:{
    credentail(){
      let list = this.cardList.filter(v=>v.platform == this.currentPlatform);
      this.result = list.map(v=>v.id)
      return list
    },
  },
  methods: {
    onCancel() {
      this.$emit("update:syncDataShow", false);
    },
    // selectPlat(v){
    //   this.cardList = this.cardList.filter(v=>v)
    // },
    onConfirm(date) {
      console.log(date);
      this.dateRang = date
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start,{tag:1})} ~ ${this.formatDate(end,{tag:1})}`;
    },
    checkAll(v) {
      if (v) {
        this.$refs.checkboxGroup.toggleAll(true);
      } else {
        this.$refs.checkboxGroup.toggleAll();
      }
    },
    toggleAll() {},
    submitSyncJob(){
      console.log(this.result);
      let params = {
        platform:this.currentPlatform || null,
        date:this.formatDate(this.dateRang[0],{tag:1}),
        endDate:this.formatDate(this.dateRang[1],{tag:1}),
        credentialIds:this.result.join(',')
      }
      if(!params.platform){
        Notify({ type: 'warning', message: '请先选择平台' });
        return false;
      }
      spendJob(params).then(res=>{
        if(res.code == 0){
          this.$router.push({name:'syncResult',query:{id:res.data[0]}})
        }
      })
    },
    getList(){
      credentailList().then(res=>{
        console.log(res);
        this.cardList = res.data;
        this.$nextTick(()=>{
          this.$refs.checkboxGroup.toggleAll(true);
        })
        
      })
    }
  },
  mounted(){
    let time = this.$route.query.time;
    console.log(time);
    let start = this.dateList.filter(v=>v.value == time)[0].start();
    let end = this.dateList.filter(v=>v.value == time)[0].end();
    this.dateRang = [start,end];
    this.onConfirm([start,end])
    this.getList()
  }
};
</script>
<style lang="scss">
.syncData {
    .item{
        border-bottom: 1px solid #ebedf0;
    }
    .van-cell__value {
      flex: 2;
    }
  .checkList {
    padding: 16px 30px;
    font-size: 24px;
    .van-checkbox {
      display: inline-flex;
      margin: 10px 0;
      margin-right: 10px;
    }
  }
  .btnWrap{
      text-align: center;
      margin-top: 60px;
      .van-button--small{
          width: 120px;
      }
  }
  .platform{
    display: flex;
    .van-dropdown-menu{
      flex: 1;
    }
    .van-dropdown-menu__bar{
      box-shadow: none;
    }
    .van-cell{
      flex: 1;
    }
  }
}
</style>